// extracted by mini-css-extract-plugin
export var wrapper = "DonateList-module--wrapper--1XhnY";
export var container = "DonateList-module--container--1jvm9";
export var layout = "DonateList-module--layout--1SYfc";
export var count2 = "DonateList-module--count2--14FFu";
export var count3 = "DonateList-module--count3--25tVE";
export var post = "DonateList-module--post--1x_h7";
export var icon = "DonateList-module--icon--yNKC5";
export var title = "DonateList-module--title--g7ouQ";
export var body = "DonateList-module--body--2WBmm";
export var cta = "DonateList-module--cta--5etRX";